import ClipboardJS from "clipboard";
import Peaks from "peaks.js";
import Plyr from "plyr";

function isInt(value) {
	if (isNaN(value)) {
		return false;
	}
	var x = parseFloat(value);
	return (x | 0) === x;
}

var TOUCH = "ontouchstart" in window;

var download_id,
	download_url,
	acf_is_init = false,
	download_email = false;

// download_email = "info@rcobiella.net",
if (window.localStorage.getItem("pourlesport_email")) {
	download_email = window.localStorage.getItem("pourlesport_email");
}

// window.localStorage.setItem('name', 'Obaseki Nosa');

jQuery(function ($) {
	function initPlyr() {
		let playerEl = document.getElementById("player");
		if (playerEl) {
			const player = new Plyr("#player");

			player.on("playing", function (e) {
				closeFixedPlayer();
			});
		}
	}
	let audioPlayers, audioTitle;
	let isPlaying = false;

	function initAudioPlyr() {
		audioPlayers = Plyr.setup(".audio-player");

		// Add class depending if it's normal or fixed player / NONSENSE
		// audioPlayers.forEach(function (singlePlayer) {
		// 	singlePlayer.on("ready", function (event) {
		// 		console.log(event);
		// 		console.log("---");
		// 	});
		// });

		let index = 0;
		while (audioPlayers && index < audioPlayers.length) {
			//console.log(index, audioPlayers[index]);
			let _index = index;

			// Play start/end
			audioPlayers[index].on("playing ended", function (e) {
				let $dis = $(this),
					$disParent = $dis.parent(),
					isFixedPlayer = $disParent.hasClass("player-fixed");

				// Don't do anything if player is fixed
				if (isFixedPlayer) {
					if (e.type == "playing") {
						$("audio").each(function () {
							let dis = this;
							if (!$(this).parents(".player-fixed").length) {
								dis.pause();
							}
						});
					}
					return false;
				}

				let audioElement = audioPlayers[_index].media; // JS audio element
				let $plyr = $(audioElement).parents(".plyr.plyr--audio"); // JQ plyr

				// Playing
				if (e.type == "playing") {
					isPlaying = _index;

					if ($plyr.length) {
						$plyr.addClass("is-playing");
						isPlaying = _index;
						//console.log("playing", $plyr);
						audioTitle = $plyr.siblings(".player-title").html();

						// Start waveform
						let $audio = $plyr.find("audio");
						let audioid = $audio.attr("data-id");

						// Stop all other players
						let $allAudios = $("audio").not(
							"#audio-player-" + audioid
						);
						$allAudios.each(function () {
							this.pause();
						});

						if (audioid) {
							//console.log("launch wave", audioid);
							if (!$audio.hasClass("wave-is-init")) {
								//waveformInit(audioid);
								const AudioContext =
									window.AudioContext ||
									window.webkitAudioContext;
								const audioContext = new AudioContext();
								let waveContainer = $("#wave-" + audioid)[0];
								let peaksOptions = {
									containers: {
										overview: waveContainer,
										zoomview: document.getElementById(
											"zoom-" + audioid
										),
									},
									height: waveContainer.height,
									mediaElement: $audio.get(0),
									webAudio: {
										audioContext: audioContext,
									},
									logger: console.error.bind(console),
									overviewHighlightColor:
										"rgba(255, 161, 39, 0)",
									segmentColor: "rgba(255, 161, 39, 0)",

									// Color of the play head
									playheadColor: "rgba(0, 0, 0, 0)",

									// Color of the play head text
									playheadTextColor: "rgba(0,0,0,0)",
									overviewWaveformColor: "#eaeaea",
									showPlayheadTime: false,
								};
								Peaks.init(peaksOptions, function (err, peaks) {
									//console.log(err, peaks);
								});
							}
							$audio.addClass("wave-is-init");
						}
					}

					// Stopping
				} else {
					isPlaying = false;
					audioTitle = false;
					if ($plyr.length) {
						$plyr.removeClass("is-playing");
					}
					//console.log(e.type, isPlaying);
				}
			});
			index++;
		}
	}

	/* -------------------------------------------------------------------------- */
	/*                              Player reposition                             */
	/* -------------------------------------------------------------------------- */
	// $("body").on("play", "audio", function () {
	// 	console.log($(this));
	// });

	/* -------------------------------------------------------------------------- */
	/*                                Play counter                                */
	/* -------------------------------------------------------------------------- */
	// Plays
	$.fn.postLikes = function (options) {
		options = $.extend(
			{
				countElement: ".like-count",
			},
			options
		);

		return this.each(function () {
			var $element = $(this),
				$count = $(options.countElement, $element),
				url = "https://" + location.host + "/wp-admin/admin-ajax.php",
				id = $element.data("id"), // Post's ID
				action = "my_update_plays",
				data = {
					action: action,
					post_id: id,
				};

			$element.on("play", function (e) {
				// Don't count multiple plays
				if ($element.hasClass("is-clicked")) {
					return false;
				}
				$element.addClass("is-clicked");
				e.preventDefault();

				$.getJSON(url, data, function (json) {
					if (json && json.count) {
						//console.log(json.count);
						//$count.text(json.count); // Update the count without page refresh
					}
				});
			});
		});
	};

	initPlyr();

	$(document).on("swup:pageView", function (event) {
		initPlyr();
		initAudioPlyr();

		// init stats
		if ($(".audio-player").length) {
			$("audio.audio-player").postLikes();
		}
		// $("#swup").css("opacity", "1");

		// if ($(".view-player").length) {
		// 	$(".view-player").css("opacity", "1");
		// }

		// Clipboard
		let clipboard = new ClipboardJS(".clipboard");
		clipboard.on("success", function (e) {
			if ($(e.trigger).length) {
				$(e.trigger).html("Copied");
				setTimeout(function () {
					$(e.trigger).html("Share");
				}, 1000);
			}

			e.clearSelection();
		});
	});

	// Before transition
	// Move player if it's active
	$(document).on("swup:willReplaceContent", function (event) {
		// If it's playing, check and move
		if (isInt(isPlaying)) {
			let audioElement = $(".plyr.is-playing:not(.plyr--paused)").find(
				"audio"
			); // JS audio element
			let $plyr = $(audioElement).parents(".plyr.plyr--audio"); // JQ plyr

			if (audioElement.length && $plyr.length) {
				$("#player-fixed").removeClass("is-active");
				$("#player-fixed .player-fixed").empty();
				$("#player-fixed .player-title").empty();
				$plyr.appendTo("#player-fixed .player-fixed");
				$("#player-fixed .player-title").html(audioTitle);
				playerTickerInit(audioTitle);
				$("#player-fixed").addClass("is-active");
			}
		}
	});

	$("body").on("click", "#player-fixed .player-close", closeFixedPlayer);

	function closeFixedPlayer() {
		$("#player-fixed").removeClass("is-active");
		$("#player-fixed .player-fixed").empty();
		$("#player-fixed .player-title").empty();
	}

	/* ---------------------------------------------------------------- */
	/*                              TICKER                              */
	/* ---------------------------------------------------------------- */

	function playerTickerInit(trackTitle) {
		let $ticker = $("ul.player-ticker-list");

		$ticker.empty();

		// If no track title, empty and stop
		if (!trackTitle) {
			return false;
		}

		let insertEl = `<li class="player-ticker-item" data-ticker="item">${trackTitle}</li>`;

		$(insertEl).appendTo($ticker);

		let tickerItem = $ticker.find('[data-ticker="item"]'),
			itemCount = $(tickerItem).length,
			viewportWidth = 0,
			tickerWidth = $(".player-ticker-viewport").outerWidth();

		function setupViewport() {
			let $tickerItem = $ticker.find(tickerItem),
				$tickerParent = $tickerItem.parent('[data-ticker="list"]'),
				elWidth = $tickerItem.outerWidth(),
				totalTimes = Math.round(tickerWidth / elWidth) + 5;

			for (let t = 0; t < totalTimes; t++) {
				$tickerItem.clone().prependTo($tickerParent);
			}
			//$ticker.find(tickerItem).clone().prependTo('[data-ticker="list"]');

			for (let i = 0; i < itemCount; i++) {
				var itemWidth = $(tickerItem).eq(i).outerWidth();
				viewportWidth = viewportWidth + itemWidth;
			}

			$ticker.css("width", viewportWidth);
		}

		function animateTicker() {
			$ticker.animate(
				{
					marginLeft: -viewportWidth,
				},
				5000,
				"linear",
				function () {
					$ticker.css("margin-left", "0");
					animateTicker();
				}
			);
		}

		function initializeTicker() {
			setupViewport();
			animateTicker();
			// $ticker
			// 	.on("mouseenter", function () {
			// 		$(this).stop(true);
			// 	})
			// 	.on("mouseout", function () {
			// 		animateTicker();
			// 	});
		}

		initializeTicker();
	}

	/* ---------------------------------------------------------------- */
	/*                          Download form                           */
	/* ---------------------------------------------------------------- */

	const $hiddenInput = $('input[name="acf[field_5f6c69c177c18]"]'),
		$emailInput = $('input[name="acf[field_5f6c6864213bd]"]'),
		$checkboxInput = $("input#acf-field_5f6c6899213be"),
		$dlForm = $("form#dl-form");

	$("body").on("click", ".download-trigger", function () {
		let $dis = $(this);
		download_id = $dis.data("id") ? $dis.data("id") : false;
		download_url = $dis.data("url") ? $dis.data("url") : false;

		if (download_id && download_url) {
			openDownloadForm(download_id, download_url);
		}
	});

	$("body").on("click", ".download-form-close", closeDownloadForm);
	$("body").on("click", ".download-overlay", function (e) {
		if ($(e.target).hasClass("download-form-container")) {
			closeDownloadForm();
		}
	});

	function openDownloadForm(id, url) {
		if (!id || !url) {
			return false;
		}
		$("body").addClass("download-form-is-active");
		$dlForm.get(0).reset();
		$hiddenInput.attr("value", id);
		if (download_email) {
			$emailInput.val(download_email);
			$checkboxInput.prop("checked", true);
		}
		$(".download-overlay").fadeIn();
	}

	function closeDownloadForm() {
		$(".download-overlay").fadeOut(function () {
			$("body").removeClass("download-form-is-active");
			$("form#dl-form").get(0).reset();
			$("form#dl-form").removeClass("is-locked");
			$(".form-response").empty().removeClass("is-active success error");
		});
	}

	function validateEmail(email) {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	// Submit
	// $("body").on("submit", "form#dl-form", function (e) {
	// 	e.preventDefault();
	// 	console.log("download link", download_url);
	// });
	if (!acf_is_init) {
		if (typeof acf != "undefined") {
			acf.do_action("ready", $("body"));

			let isValidating = false;

			acf.add_action("validation_begin", () => {
				isValidating = true;
			});

			acf.add_action("submit", ($form) => {
				isValidating = false;
			});

			let isSubmitted = false;

			$("body").on("submit", "form#dl-form", function (e) {
				var $form = $(e.target);
				var $contactMsg = $form.siblings(".form-response");
				e.preventDefault();
				e.stopPropagation();

				if (!validateEmail($emailInput.val())) {
					$emailInput.addClass("has-error");
					setTimeout(function () {
						$emailInput.removeClass("has-error");
					}, 3000);
					return false;
				}
				if (!isValidating && !isSubmitted) {
					isSubmitted = true;
					$form.addClass("is-locked");

					if (
						$checkboxInput.prop("checked") &&
						$emailInput.val() &&
						validateEmail($emailInput.val())
					) {
						window.localStorage.setItem(
							"pourlesport_email",
							$emailInput.val()
						);
						download_email = $emailInput.val();
					}

					//
					if (!$checkboxInput.prop("checked")) {
						window.localStorage.clear();
						download_email = false;
					}

					// Success message
					var dl_link,
						dl_link_error = false;
					if (download_url) {
						dl_link = `Your download should now start automatically. If it doesn't, you can <a data-no-swup id="temp-download-link" href="${download_url}" download>click here</a> to download the file.`;
						dl_link_error = false;
					} else {
						dl_link =
							"There has been an error processing your request. Please, try again later or contact us via email.";
						dl_link_error = true;
					}
					$.ajax({
						url: window.location.href,
						method: "post",
						data: $form.serialize(),
						success: (data) => {
							$contactMsg
								.html(dl_link)
								.addClass("is-active success");

							// Auto DL
							if (!dl_link_error) {
								setTimeout(function () {
									$("#temp-download-link")[0].click();
								}, 2000);
							}
							// setTimeout(function () {
							// 	$contactMsg.removeClass("is-active success");
							// 	$form[0].reset();
							// 	$form.removeClass("is-locked");
							// }, 3000);
							isSubmitted = false;
						},
						error: (data) => {
							$contactMsg
								.html(
									"This doesn't look like a valid email address."
								)
								.addClass("is-active error");
							setTimeout(function () {
								$contactMsg.removeClass("is-active error");
								$form.removeClass("is-locked");
							}, 3000);
							isSubmitted = false;
						},
					});
				}
			});
		}
		acf_is_init = true;
	}
});
